.App .stream-page-title a {
  text-decoration: none;
  color: #0969da;
}

.App .stream-page-title a:visited {
  color: #0969da;
}

.App .stream-page-title a:hover {
  text-decoration: underline;
}